<template>
  <div class="full-height">
    <v-row class="first-row">
      <v-col class="full-height">
        <v-card class="full-height">
          <v-card-title>{{$t('t.Payments')}}</v-card-title>
          <v-card-text class="card-text-full-height pa-0 overflow-y-auto">
            <div class="vh-related pb-2">
              <graph
                v-if="!isNaN(paymentsData.payments)"
                :options="barOptions"
                :series="barSeries"
                height="100%"
                width="100%"
                type="bar"
                class="full-height mt-n4 mr-4"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="second-row">
      <v-col
        class="full-height"
        cols=6
      >
        <v-card class="full-height mr-0">
          <v-card-title>{{$t('t.CEI')}}</v-card-title>
          <v-card-text class="mt-n7 card-text-full-height">
            <span class="font-weight-bold text-h6 mr-1">{{ceiToday}}</span>
            <span :class="ceiTendency[0]==='+'?'cei-tendency-up':'cei-tendency-down'">{{ceiTendency}}</span>
            <graph
              v-if="accountsData.cei"
              :options="options"
              :series="seriesCEI"
              type="area"
              height="100%"
              width="100%"
              class="full-height vertical-center"
              ref="cei"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        class="full-height"
        cols=6
      >
        <v-card class="full-height ml-0">
          <v-card-title>{{$t('t.DSO')}}</v-card-title>
          <v-card-text class="mt-n7 card-text-full-height">
            <span class="font-weight-bold text-h6">{{dsoToday}} {{$t('t.Days')}}</span>
            <graph
              v-if="accountsData.dso"
              :options="options"
              :series="seriesDSO"
              type="area"
              height="100%"
              width="100%"
              class="full-height vertical-center"
              ref="dso"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {
    Graph: () => import('@/components/graph')
  },
  data () {
    return {
      accountsData: {},
      paymentsData: {}
    }
  },
  methods: {
    async refreshData () {
      const http = this.$http().versionHashed()
      this.accountsData = {}
      this.paymentsData = {}
      const [paymentsRequest, accountRequest] = await Promise.all(
        [
          http.get('/core/v6/dashboard/agent/payments/' + this.showAll),
          http.get('/core/v6/dashboard/agent/accounts-kpi/' + this.showAll)
        ]
      )
      this.accountsData = accountRequest.data
      this.paymentsData = paymentsRequest.data
    }
  },
  computed: {

    ceiToday () {
      return this.accountsData?.cei?.[this.accountsData.cei.length - 1] ?? 0
    },
    ceiTendency () {
      const tendency = this.ceiToday - this.accountsData?.cei?.[this.accountsData.cei.length - 2] ?? 0
      if (Number.isNaN(tendency)) {
        return ''
      }
      return `${tendency >= 0 ? '+' : ''}${tendency} %`
    },
    dsoToday () {
      return this.accountsData.dso ? this.accountsData.dso[this.accountsData.dso.length - 1] : 0
    },
    seriesCEI () {
      return [{
        name: '',
        data: this.accountsData.cei
      }]
    },
    seriesDSO () {
      return [{
        name: '',
        data: this.accountsData.dso
      }]
    },

    options () {
      return {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: this.accountsData.dates || [],
          tickPlacement: 'on'
        },
        yaxis: {
          show: false
        },
        tooltip: {
          x: {
            formatter: v => this.formatDate(v)
          }
        }
      }
    },
    barSeries () {
      return [
        {
          name: this.$t('t.MatchedPayment'),
          data: [this.paymentsData.payments]
        },
        {
          name: this.$t('t.UnappliedCash'),
          data: [this.paymentsData.unappliedCash]
        }
      ]
    },
    barOptions () {
      return {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%'
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: -8
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        tooltip: {
          y: {
            formatter: v => this.formatCurrency(v)
          }
        },
        fill: {
          opacity: 1
        },
        xaxis: {
          categories: [this.$t('t.Payments')],
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          forceNiceScale: false,
          show: false,
          showAlways: false
        }
      }
    }
  },
  watch: {
    showAll: {
      handler (v) {
        if (typeof v !== 'undefined') {
          this.refreshData()
        }
      },
      immediate: true
    }
  },
  props: {
    showAll: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.first-row
  height 40%

.second-row
  height 60%

.full-height
  height 100%

.card-text-full-height
  height calc(100% - 64px)

.vertical-center
  display flex
  flex-direction column
  justify-content center

.cei-tendency-up
  color green

.cei-tendency-down
  color red

>>> .apexcharts-tooltip
  margin-top 25px
</style>
